/* TABLE STYLES
   ----------------------------- */

table.table {
  // border-radius: 3px;
  // border-style: hidden;
  border: solid 1px red;
  width: 100%;
  color: $tableTextColor;

  thead {
    background: $tableBgColorHeader;

    th {
      font-weight: 300;
      padding: 15px 20px;
      text-transform: uppercase;
      border-bottom: $tableHeaderBorderColor;
    }
  }

  tbody {
    tr {
    }
    tr:hover {
      background: #f4f4f4;
    }
    tr:hover .display-none {
      display: inline;
    }
    td {
      padding: 5px;
      border-radius: 3px;
      vertical-align: middle;
      border-bottom: $tableRowBorderColor;
    }
  }

  &.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: $stripedTableColor;
  }
}

table.table-hover {
  > tbody {
    > tr {
      &:hover {
        background-color: $tableBgRowHover;
      }
    }
  }
}

.table-responsive {
  padding: 1px;
}
