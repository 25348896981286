.btn {
  display: inline-block;
  color: #fff;
  border: none;
  margin: 0;
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}
.btn-primary {
  border: 1px solid #2069b2;
  font-size: 15px;
  padding: 10px 15px;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  border-radius: 4px;
  background-color: #3085da;
  color: #fff;
}

.btn-primary:hover {
  background-color: #3ba0f3;
  color: #fff;
}

.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary.disabled:hover {
  cursor: not-allowed;
  color: #888;
  background-color: #ddd;
  border: solid 1px #dedede;
}
.btn.btn-secondary {
  border: 1px solid #ccc;
  font-size: 15px;
  padding: 10px 8px;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  border-radius: 4px;
  background-color: #fff;
  color: #000;
  box-shadow: none;
}
.form-control {
  padding: 0px 5px;
  border: solid 1px #aaa;
  color: #000;
}
.fullscreen-modal {
  padding: 0 !important;
}
.fullscreen-modal .modal-dialog {
  width: 100%;
  max-width: none;
  height: 100%;
  margin: 0;
}
.fullscreen-modal .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.fullscreen-modal .modal-body {
  overflow-y: auto;
  padding: 0px;
}
.modal-header {
  background: #ededed;
  background: linear-gradient(#fff, #ddd);
  border-bottom: solid 1px #d5d9d9;
}
.page-item.active .page-link {
  z-index: 0;
}
.react-bootstrap-table table {
  table-layout: auto !important;
  border-collapse: collapse;
  width: 100%;
  border: 0;
}
.react-bootstrap-table table thead tr th {
  // padding: 7px;
  // width: 1%;
  color: #000;
  /* text-transform: capitalize; */
  text-transform: none;
  font-weight: normal;
  /* display: none; */
}
.react-bootstrap-table table tbody tr td {
  border: 0;
  // border-bottom: solid 1px #ccc;
  width: auto;
  cursor: pointer;
}
.react-bootstrap-table .sortable .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.react-bootstrap-table .sortable .dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: '';
  border-top: 0;
  border-bottom: 4px dashed;
}
.react-bootstrap-table table tbody tr:hover {
  background: none;
}
.react-bootstrap-table table tbody tr:hover .hide-on-hover {
  opacity: 0;
}
.react-bootstrap-table .selectTable tr td:first-child {
  // width: 1%;
  text-align: center;
}
.react-bootstrap-table .checkins-table th {
  padding: 10px 5px;
  vertical-align: text-top;
  border: 0;
}
.react-bootstrap-table .checkins-table tr:hover {
  background: #f8f8f8;
  color: #000;
}
.react-bootstrap-table .checkins-table td {
  padding-top: 15px;
  border-bottom: solid 1px #ccc;
  cursor: default;
}
.react-bootstrap-table .checkins-table th:first-child {
  width: 1%;
}
// .react-bootstrap-table .checkins-table th:nth-child(2) {
//   width: 1%;
// }
.react-bootstrap-table .checkins-table th:last-child {
  width: 1%;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
}
.react-bootstrap-table .checkins-table th:nth-last-child(2) {
  // width: 1%;
  white-space: nowrap;
}
.react-bootstrap-table .checkins-table td:first-child .btn {
  padding: 5px 8px;
  width: 100%;
}
.react-bootstrap-table .checkins-table td:first-child .form-control {
  // border: solid 1px red;
  height: 1.5rem;
}
.react-bootstrap-table .checkins-table td:first-child .dropdown-toggle:after {
  display: none;
}
.react-bootstrap-table .checkins-table th:nth-child(2) {
  padding-left: 5px;
}
.react-bootstrap-table .checkins-table tbody tr td {
  text-align: start;
  vertical-align: top;
}
.react-bootstrap-table .checkins-table td:nth-child(2) {
  cursor: pointer;
}
.react-bootstrap-table .checkins-table td:first-child {
  padding: 15px 10px;
}
.react-bootstrap-table .checkins-table td:last-child {
  width: 1%;
  // text-align: right;
}
.react-bootstrap-table .checkins-table .dropdown {
  width: 100%;
}
.checkins-table .dropdown > .btn,
.checkins-table .notified-container > .btn {
  width: 100%;
}
.react-bootstrap-table .checkins-table .show .dropdown-menu {
  padding: 5px;
}
.react-bootstrap-table .customer_name_select_table thead {
  display: none;
}
.react-bootstrap-table .customer-filter-table thead:first-child th:first-child {
  width: 1%;
}
.react-bootstrap-table .customer-filter-table td {
  padding: 0px;
}
.react-bootstrap-table .customer-filter-table tr td:first-child {
  // border: solid 1px red;
  text-align: center;
}
.react-bootstrap-table .customer-filter-table tr:hover {
  background: #f8f8f8;
  color: #000;
}
.react-bootstrap-table .customer-select-table td {
  padding: 0px;
}
.react-bootstrap-table .customer-select-table tr {
  border-bottom: solid 1px #eee;
}
.react-bootstrap-table .customer-select-table tr:hover {
  background: #f8f8f8;
  color: #000;
}
.react-bootstrap-table .products-table tr td {
  border-bottom: solid 1px #f4f4f4;
  padding: 0px;
}
.react-bootstrap-table .products-table thead tr th {
  border: 0;
  color: #000;
  text-transform: capitalize;
  font-weight: bold;
  display: none;
}
.react-bootstrap-table .products-table tr td:first-child {
  text-align: left;
}
.react-bootstrap-table .products-table tr td:nth-child(1) {
  text-align: left;
}
.react-bootstrap-table .products-table tr td:nth-child(2) {
  text-align: left;
  white-space: nowrap;
}
.react-bootstrap-table .products-table tr td:last-child {
  width: 1%;
}
.react-bootstrap-table .products-table .products-table-expandedRow-body {
  padding: 0px;
}
.react-bootstrap-table .products-table .products-table-expandedRow-body:hover {
  background: #fff;
}
.react-bootstrap-table .staff-select-table thead {
  display: none;
}
.react-bootstrap-table .staff-select-table td {
  padding: 0px;
}
.react-bootstrap-table .staff-select-table tr {
  border-bottom: solid 1px #eee;
}
.react-bootstrap-table .staff-select-table tr:hover {
  background: #f8f8f8;
  color: #000;
}
.react-bootstrap-table-pagination {
  // padding: 15px;
  align-items: center;
}
.react-bootstrap-table-pagination-list {
  float: none;
  align-items: center;
  justify-content: flex-end;
  display: flex;
}
.react-bootstrap-table-pagination-list > ul {
  margin: unset;
}
.react-bootstrap-table-pagination .active .page-link {
  background-color: #f4f4f4;
  border: solid 1px #ccc;
  color: #000;
}
.row {
  /* - added by Bradley C - BootstrapTable css default class .row has a margin that causes window to have horizontal scroll, this fixes that issue */
  margin-right: 0px;
  margin-left: 0px;
}
.react-bootstrap-table-pagination-list .pagination {
  float: right;
}
.react-tel-input .form-control {
  width: 100%;
  padding: 30px 20px 30px 58px;
}
.btn.btn-secondary {
  font-size: unset;
}
.table-bordered th,
.table-bordered td {
  border: none;
}
.btn-toolbar .btn-group > .btn {
  padding: 10px;
  /* background-color: #1f2027; */
  /* border: solid 1px #1f2027; */
  background-color: transparent;
  border: 0;
  color: #000;
  font-size: 1.25em;
  text-transform: capitalize;
}
.btn-toolbar .btn-group > .btn:active {
  background-color: transparent;
}
.btn-toolbar .btn-group > .btn:hover {
  /* background-color: #0062cc; */
  color: #000;
  background-color: #eee;
}
/* fade background modal when multiple modals are on top of each other or overlap */
.modal.fade {
  // background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(2px);
}
.modal.one-checkinapp-custom-modal {
  background: 0;
  /* transform: translate(-27vw, 12vh); */
}
.agreement-modal {
  /* border: solid 5px #fff; */
  min-width: 95vw;
  min-height: 95vh;
  height: 95vh;
}
.agreement-modal.modal-content {
  min-height: 99vh;
  min-width: 99vw;
}
/* .icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
} */
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  /* border: solid 1px #000; */
  /* box-shadow: #888; */
  border: solid 1px #019fe8;
  background: #bcefff;
  /* box-shadow: 0px 0px 5px #888888; */
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #000;
}
#main-menu .menu-expand-icon {
  top: 12px;
}
/*************** TABS ***************/
#page-tab-container {
  /* border: solid 1px red; */
  /* display: flex; */
  justify-content: center;
}
.nav {
  justify-content: start;
  flex-wrap: nowrap;
  /* display: flex; */
  justify-content: start;
}
.nav-tabs {
  /* border-bottom: solid 1px #ccc; */
  /* border-top: 0; */
  /* border: solid 1px red; */
  padding-top: 5px;
  padding-bottom: 10px;
  display: flex;
  border-bottom: 0;
  /* max-width: 980px; */
  /* justify-content: space-evenly; */
}
.nav-tabs .nav-link {
  color: #555;
  /* flex-grow: 1; */
  border: 0;
  border-radius: 0;
  margin-top: 0px;
  /* border: solid 1px red; */
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #019fe8;
  border: none;
  background-color: transparent;
  border-bottom: solid 1px #019fe8;
  border-radius: 0;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  /* margin-bottom: -1px; */
  border: 0;
  /* border-radius: 0; */
  border-bottom: solid 1px #019fe8;
  outline: none;
}
.search-field .btn-default {
  border: solid 1px #ccc;
  color: #000;
}
.MuiTabs-scroller button:focus {
  outline: none;
}
.MuiTabs-indicator {
  border-bottom: solid 3px #019fe8;
}
