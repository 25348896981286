/* GLOBAL STYLES
   ----------------------------- */

body {
  background-color: $globalBackgroundColor;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  border-color: $hrColor;
}
