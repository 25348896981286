/* BUTTON STYLES
   ----------------------------- */
.btn {
  border-radius: $btnBorderRadius;
  transition: all $btnTransitionSpeed ease;

  &.btn-primary {
    background-color: $btnColorPrimary;
    @include buttonBorder($btnColorPrimary);

    &:focus,
    &:hover {
      background-color: darken($btnColorPrimary, 10%);
      @include buttonBorder(darken($btnColorPrimary, 10%));
    }
  }

  &.btn-secondary {
    @include defaultButtonStyle;
  }

  &.btn-success {
    color: #fff;
    background-color: $btnColorSuccess;
    @include buttonBorder($btnColorSuccess);

    &:focus,
    &:hover {
      background-color: darken($btnColorSuccess, 10%);
      @include buttonBorder(darken($btnColorSuccess, 10%));
    }
  }

  &.btn-danger {
    color: #fff;
    background-color: $btnColorDanger;
    @include buttonBorder($btnColorDanger);

    &:focus,
    &:hover {
      background-color: darken($btnColorDanger, 10%);
      @include buttonBorder(darken($btnColorDanger, 10%));
    }
  }

  &.btn-info {
    background-color: $btnColorInfo;
    @include buttonBorder($btnColorInfo);

    &:focus,
    &:hover {
      background-color: darken($btnColorInfo, 5%);
      @include buttonBorder(darken($btnColorInfo, 10%));
    }

    &:disabled {
      background-color: #ccc;
      color: #333;
      border: 0;
    }
  }

  &.btn-disabled {
    color: #000;
    background-color: $colorDisabled;
    @include buttonBorder($colorDisabled);

    &:focus,
    &:hover {
      color: #000;
      background-color: darken($colorDisabled, 5%);
      @include buttonBorder(darken($colorDisabled, 10%));
    }

    &:disabled {
      background-color: #ccc;
      color: #333;
      border: 0;
    }
  }

  &.btn-warning {
    color: #fff;
    background-color: $btnColorWarning;
    @include buttonBorder($btnColorWarning);

    &:focus,
    &:hover {
      background-color: darken($btnColorWarning, 10%);
      @include buttonBorder(darken($btnColorWarning, 10%));
    }

    &:active {
      color: #fff !important;
    }
  }

  &.btn-outline-primary {
    @include buttonBorder($btnColorPrimary);

    &:focus,
    &:hover {
      @include buttonBorder($btnColorPrimary);
    }
  }
  &.btn-outline-secondary {
    @include buttonBorder($btnColorSecondary);

    &:focus,
    &:hover {
      @include defaultButtonStyle;
      @include buttonBorder(darken($btnColorSecondary, 10%));
    }
  }
  &.btn-outline-success {
    @include buttonBorder($btnColorSuccess);

    &:focus,
    &:hover {
      @include buttonBorder($btnColorSuccess);
    }
  }
  &.btn-outline-info {
    @include buttonBorder($btnColorInfo);

    &:focus,
    &:hover {
      @include buttonBorder($btnColorInfo);
    }
  }
  &.btn-outline-warning {
    @include buttonBorder($btnColorWarning);

    &:focus,
    &:hover {
      @include buttonBorder($btnColorWarning);
    }
  }
  &.btn-outline-danger {
    @include buttonBorder($btnColorDanger);

    &:focus,
    &:hover {
      @include buttonBorder($btnColorDanger);
    }
  }
}
