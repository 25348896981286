@import url('https://use.fontawesome.com/releases/v5.0.9/css/all.css');

@media (max-width: 800px) {
  .fc-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .fc-calendar-container {
    min-width: 100%;
  }
}
@media (max-width: 480px) {
  .fc-calendar-container {
    min-width: 100%;
  }
}
/* unhighlight when on mobile */
@media (hover: none) {
  .fc .fc-daygrid-day-frame:hover {
    background: none;
  }
}
/* CALENDAR EXTERIOR */
.fc .fc-scrollgrid,
.fc-daygrid table td {
  border: none;
}
/* .fc-scrollgrid thead tr:nth-child(1) th {
  border: solid 1px blue;
  position: sticky;
  top: 0;
  background: #ffffff;
} */
.fc-center {
  display: flex;
  padding: 0;
  margin: 0;
}
.fc-schedule-resource .fc .fc-col-header-cell-cushion {
  padding: 5px;
  font-weight: normal;
  color: #000;
}
.fc .fc-button:focus {
  box-shadow: none;
}
/* RESOURCE CALENDAR */
.fc-schedule-resource .fc-timegrid-slot {
  cursor: pointer;
}
.fc-schedule-resource .fc .fc-timegrid-axis-cushion,
.fc-schedule-resource .fc .fc-timegrid-slot-label-cushion {
  cursor: default;
}
.fc-schedule-resource .fc .fc-timegrid-slot {
  height: 2.5em;
}
.fc-calendar-container {
  max-width: 320px;
}
.fc-left {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.fc-right {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.fc-schedule-resource .fc-day-today {
  background: none !important;
}
.fc-day-today .fc-daygrid-day-frame {
  border: solid 0px #ccc;
  color: #ff8a00;
  border-radius: 5px;
}
/* event constraint background color */
.fc-schedule-resource .fc-event {
  cursor: pointer;
}
/* prev and next button */
.fc-resourceTimeGridDay-button,
.fc-dayGridMonth-button,
.fc-timeGridWeek-button,
.fc-timeGridDay-button,
.fc-listWeek-button,
.fc-button-group .fc-prev-button,
.fc-button-group .fc-next-button {
  background: #fff;
  color: #333;
  border: none;
  outline: none;
}
.fc-resourceTimeGridDay-button:hover,
.fc-dayGridMonth-button:hover,
.fc-timeGridWeek-button:hover,
.fc-timeGridDay-button:hover,
.fc-listWeek-button:hover,
.fc-button-group .fc-prev-button:hover,
.fc-button-group .fc-next-button:hover,
.fc-dayGridMonth-button:active:focus {
  color: #000;
  border: none;
  background: #fff;
  outline: none;
}
.fc-schedule-header .fc-toolbar-chunk .fc-today-button {
  color: #ff8a00;
}
.fc-schedule-header .fc-toolbar-chunk .fc-today-button:hover {
  background: #f1f1f1;
  color: #ff8a00;
  border: solid 1px #ccc;
}
.fc-schedule-header.fc-toolbar-chunk .fc-today-button:disabled {
  background: #bcefff;
  border: solid 1px #019fe8;
}
.fc-schedule-header .fc-toolbar-chunk .fc-today-button:disabled:hover {
  background: #bcefff;
}
.fc-schedule-resource .fc .fc-timegrid-axis-chunk td:nth-child(1) {
  position: relative;
  border: none;
}
.fc-schedule-resource .fc .fc-timegrid-axis-chunk tr:first-child td div {
  display: none;
}
.fc-schedule-resource .fc-direction-ltr .fc-timegrid-slot-label-frame {
  position: absolute;
  top: -10px;
  width: 100%;
  color: #000;
}
.fc-schedule-resource .fc-toolbar-chunk {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.fc-schedule-resource .fc .fc-toolbar-title {
  font-size: 1.5em;
}
.fc-schedule-resource .fc .fc-timegrid-event {
  /* border-left: solid 3px #019fe8; */
  margin-left: -2px;
  /* margin-top: 1px; */
  background: #bcefff;
  color: #000;
  border-radius: 0;
}
.fc-schedule-resource .fc-v-event {
  border: none;
  /* border-left: solid 4px #000; */
}
.fc-schedule-resource .fc-v-event .fc-event-main {
  color: #000;
}
.fc-schedule-resource .fc-timegrid-event-condensed .fc-event-main-frame {
  white-space: nowrap;
}
.fc-schedule-resource .fc-timegrid-event-condensed .fc-event-title {
  font-size: 1em;
}
.fc-schedule-calendar .fc .fc-non-business,
.fc-schedule-resource .fc .fc-non-business {
  cursor: pointer;
}
.fc-schedule-calendar .fc .fc-daygrid-day-frame {
  max-height: 45px;
  cursor: pointer;
}
.fc-schedule-calendar .fc .fc-daygrid-day-frame:hover {
  background: #dbf6ff;
  border-radius: 5px;
}
.fc-schedule-calendar .fc-scroller table tbody tr th {
  border: none;
}
.fc-schedule-calendar .fc-scroller table tbody tr th.fc-col-header-cell.fc-resource {
  border-bottom: solid 1px #ddd;
}
.fc-schedule-calendar .week-highlight {
  background: #bcefff;
  border: solid 1px #019fe8;
}
.fc-schedule-calendar .fc-toolbar-chunk,
.fc-schedule-calendar .fc .fc-day.fc-day-past {
  color: #ccc;
}
.fc-schedule-calendar .fc-direction-ltr .fc-button-group > .fc-button:not(:last-child),
.fc-schedule-resource .fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
  background: #fff;
  color: #000;
}
.fc-schedule-calendar .fc .fc-toolbar-title {
  font-size: 1em;
  white-space: nowrap;
  color: #000;
}
.fc-schedule-calendar .fc .fc-toolbar.fc-header-toolbar,
.fc-schedule-resource .fc .fc-toolbar.fc-header-toolbar {
  margin-top: 15px;
  margin-bottom: 15px;
}
/* default background for today is yellowish so we'll change to none */
.fc-schedule-calendar .fc-day-today {
  background: none !important;
}
.fc-schedule-calendar .fc .fc-day-today .fc-daygrid-day-top {
  opacity: unset;
}
.fc-schedule-calendar .fc-toolbar-chunk div,
.fc-schedule-resource .fc-toolbar-chunk div,
.fc-schedule-list .fc-toolbar-chunk div,
.fc-schedule-header .fc-toolbar-chunk div {
  display: flex;
  align-items: center;
}
.fc-schedule-calendar .fc-toolbar-chunk .fc-prev-button,
.fc-schedule-calendar .fc-toolbar-chunk .fc-next-button,
.fc-schedule-resource .fc-toolbar-chunk .fc-prev-button,
.fc-schedule-resource .fc-toolbar-chunk .fc-next-button,
.fc-schedule-header .fc-toolbar-chunk .fc-prev-button,
.fc-schedule-header .fc-toolbar-chunk .fc-next-button {
  background: #fff;
  color: #333;
  border: none;
  outline: none;
}
.fc-schedule-calendar .fc .fc-col-header-cell-cushion {
  padding: 5px;
  font-weight: lighter;
  color: #777;
  text-transform: uppercase;
}
.fc-schedule-calendar .fc-daygrid-day-number {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fc-schedule-list {
  margin-top: 15px;
}
.fc-schedule-list .fc .fc-toolbar-title {
  font-size: 1em;
}
.fc-schedule-list .fc-theme-standard .fc-list {
  border: none;
}
.fc-schedule-list .fc-toolbar-chunk .fc-button {
  border: 0;
  border-bottom: solid 1px #aaa;
  background: #fff;
  color: #000;
}
.fc-schedule-list .fc-list-day-text {
  text-transform: uppercase;
}
.fc-schedule-list .fc .fc-list-table .fc-cell-shaded {
  border: 0;
  background: #bcefff;
  font-weight: normal;
}
.fc-schedule-list .fc .fc-event-past .fc-list-event-dot {
  border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid var(--fc-event-border-color, #ddd);
}
.fc-schedule-header .fc-view-harness {
  display: none;
}
.fc-schedule-header .fc-theme-standard {
  width: 100%;
}
.fc-schedule-header .fc-settings-button {
  background: #fff;
  color: #333;
  border: solid 1px #ccc;
}
.fc-schedule-header .fc-button {
  background: #fff;
  color: #333;
  border: solid 1px #ccc;
}
.fc-schedule-header .fc-button:hover {
  background: #f1f1f1;
  color: #000;
  border: solid 1px #ccc;
}
.fc-schedule-header .fc .fc-button-primary:not(:disabled):active:focus,
.fc-schedule-header .fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: none;
}
.fc-schedule-header .fc-book-button {
  background: #019fe8;
  color: #fff;
  border: none;
}
.fc-schedule-header .fc .fc-button-primary:disabled {
  background: #bcefff;
  border: solid 1px #019fe8;
  opacity: 100%;
  color: #000;
}
.fc-schedule-header .fc .fc-button-primary:not(:disabled).fc-button-active {
  background: #bcefff;
  color: #000;
  border: solid 1px #019fe8;
}
.fc-schedule-header .fc .fc-button-primary:not(:disabled):active {
  background: #f1f1f1;
  color: #000;
  border: solid 1px #ccc;
}
/* .fc .fc-button-primary:not(:disabled).fc-button-active  */
.fc .fc-toolbar.fc-header-toolbar {
  margin: 0;
}
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk {
  /* border: solid 1px red; */
  display: flex;
}
/* CELLS */
.fc-schedule-calendar .fc-daygrid-event-dot {
  margin: auto;
}
.fc-schedule-calendar .fc-day-past .fc-daygrid-event-dot {
  border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid var(--fc-event-border-color, #ddd);
}
.fc-schedule-calendar .fc-daygrid .selectedDate .fc-daygrid-day-frame {
  background-color: #bcefff !important;
  border-radius: 5px;
  color: #000;
  border: solid 1px #019fe8;
}
.fc-schedule-calendar .fc-daygrid .fc-daygrid-day-frame:hover {
  background: #eee;
}
.fc-schedule-calendar .fc .fc-daygrid-event-harness .fc-event-time {
  display: none;
}
.fc-schedule-calendar .fc .fc-daygrid-event-harness .fc-event-title {
  display: none;
}
.fc-schedule-calendar .fc-daygrid-dot-event:hover {
  background: none !important;
}
.fc-schedule-calendar .fc-daygrid-day-bottom {
  display: none;
}
.fc-schedule-calendar a.fc-daygrid-more-link {
  display: none;
}

/* BOOKING CALENDAR */
.fc-booking-calendar .fc .fc-non-business {
  /* background-image: linear-gradient(45deg, #eaeaea 25%, transparent 25%, transparent 50%, #eaeaea 50%, #eaeaea 75%, transparent 75%, transparent);
  background-size: 5px 5px; */
  background: #fff;
  /* background: red; */
}
.fc-booking-calendar .non-business-day {
  color: #ccc;
  cursor: default;
}
.fc-booking-calendar .is-business-day {
  color: #000;
  cursor: pointer;
}
.fc-booking-calendar .fc .fc-daygrid-day-frame:hover {
  background: none !important;
  /* background: #; */
}
.fc-booking-calendar .fc-scroller table tbody tr th {
  border: none;
  /* border-bottom: solid 1px red; */
}
.fc-booking-calendar .fc-toolbar-chunk {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.fc-booking-calendar .fc .fc-day.fc-day-past {
  color: #ccc;
  cursor: default;
}
.fc-booking-calendar .fc-direction-ltr .fc-button-group > .fc-button:not(:last-child),
.fc-schedule-resource .fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
  background: #fff;
  color: #000;
}
.fc-booking-calendar .fc .fc-toolbar-title {
  font-size: 1em;
  text-transform: uppercase;
}
.fc-booking-calendar .fc .fc-toolbar.fc-header-toolbar {
  margin-top: 15px;
  margin-bottom: 15px;
  /* border-top: solid 1px #ccc; */
  border-bottom: solid 1px #ccc;
}
/* default background for today is yellowish so we'll change to none */
.fc-booking-calendar .fc-day-today {
  background: none !important;
}
.fc-booking-calendar .fc .fc-day-today .fc-daygrid-day-top {
  opacity: unset;
}
/* HEADER START */
.fc-booking-calendar .fc .fc-col-header-cell-cushion {
  padding: 5px;
  font-weight: lighter;
  color: #777;
  text-transform: uppercase;
}
.fc-booking-calendar .fc-daygrid-day-number {
  width: 100%;
  /* height: 100%; */
  min-height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: solid 1px red; */
}
/* CELLS */
.fc-booking-calendar .fc .fc-daygrid-day-events {
  display: none;
}
.fc-booking-calendar .fc-daygrid-event-dot {
  margin: auto;
}
.fc-booking-calendar .fc-daygrid .selectedDate .fc-daygrid-day-frame {
  background-color: #bcefff !important;
  border-radius: 5px;
  font-weight: bold;
  border: solid 1px #019fe8;
}
.fc-booking-calendar .fc-daygrid .fc-daygrid-day-frame:hover {
  background: #eee;
}
.fc-booking-calendar .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  background: none !important;
  color: #000;
  border: none;
}
.fc-booking-calendar .fc .fc-button:not(:disabled) {
  background: none !important;
  color: #000;
  border: none;
}
.fc-booking-calendar .fc .fc-button-primary:disabled {
  background: none !important;
  color: #fff;
  border: none;
}
.fc-booking-calendar .fc .fc-day-disabled {
  background: #fff;
}
.fc-booking-calendar .fc .fc-daygrid-event-harness .fc-event-time {
  display: none;
}
.fc-booking-calendar .fc .fc-daygrid-event-harness .fc-event-title {
  display: none;
}
.fc-booking-calendar .fc-daygrid-dot-event:hover {
  background: none !important;
}
.fc-booking-calendar .fc-daygrid-day-bottom {
  display: none;
}
.fc-booking-calendar a.fc-daygrid-more-link {
  display: none;
}
.fc-booking-items {
  display: flex;
  flex-direction: column;
}
.booking-time-button {
  padding: 20px;
  background: #fff;
  border: solid 1px #ccc;
  color: #000;
  border-radius: 3px;
  margin-right: 10px;
}
.booking-time-button-selected {
  padding: 20px;
  background: #bcefff;
  border: solid 1px #007bff;
  color: #000;
  margin-right: 10px;
  border-radius: 3px;
  font-weight: bold;
}
