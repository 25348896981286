@font-face {
  font-family: 'AlexBrush';
  src: local('AlexBrush'), url('../../assets/fonts/AlexBrush-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'AlwaysForever';
  src: local('AlwaysForever'), url('../../assets/fonts/Always-Forever.ttf') format('truetype');
}
@font-face {
  font-family: 'AmazonEmber';
  src: local('AmazonEmber'), url('../../assets/fonts/AmazonEmber.ttf') format('truetype');
}
@font-face {
  font-family: 'Cabin';
  src: local('Cabin'), url('../../assets/fonts/Cabin-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'CeraBasic';
  src: local('CeraBasic'), url('../../assets/fonts/Cera-Basic.ttf') format('truetype');
}
@font-face {
  font-family: 'GoogleSans';
  src: local('GoogleSans'), url('../../assets/fonts/GoogleSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'GoogleSansDisplay';
  src: local('GoogleSansDisplay'), url('../../assets/fonts/GoogleSansDisplay-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url('../../assets/fonts/Lato-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Niconne';
  src: local('Niconne'), url('../../assets/fonts/Niconne-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'NotoSans';
  src: local('Noto'), url('../../assets/fonts/NotoSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans'), url('../../assets/fonts/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'PhotographSignature';
  src: local('PhotographSignature'), url('../../assets/fonts/Photograph-Signature.ttf') format('truetype');
}
@font-face {
  font-family: 'PoiretOne';
  src: local('PoiretOne'), url('../../assets/fonts/PoiretOne-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNova';
  src: local('ProximaNova'), url('../../assets/fonts/proxima-nova.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('../../assets/fonts/Roboto-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'SegoeUI';
  src: local('SegoeUI'), url('../../assets/fonts/Segoe-UI.ttf') format('truetype');
}
@font-face {
  font-family: 'Yellowtail';
  src: local('Yellowtail'), url('../../assets/fonts/Yellowtail-Regular.ttf') format('truetype');
}

@media (min-width: 640px) {
  .page-master {
    flex: 2;
    max-width: 640px;
    /* border: solid 1px red; */
  }
  .page-slave {
    flex: 1;
    border-left: solid 1px #ccc;
    /* border: solid 1px red; */
  }
  .waitlist-width {
    width: 80%;
  }
  .mobile-justify-content {
    justify-content: space-between;
  }
}
@media (max-width: 640px) {
  .mobile-hidden {
    display: none;
  }
  .waitlist-width {
    width: 100%;
  }
  .mobile-justify-content {
    justify-content: center;
  }
}
@media print {
  html,
  body {
    height: 100%;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

html,
body {
  font-family: 'GoogleSans', 'GoogleSansDisplay', 'AmazonEmber', 'LatoRegular', 'Roboto', 'NotoSans', 'ProximaNova', 'Segoe UI', 'Open Sans',
    'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  /* font-size: 1.15em; */
}
#root {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* border: solid 1px red; */
}
.global-level-alert {
  margin-bottom: 0;
  border-radius: 0;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.alert-warning {
  background-color: #ff9900;
  color: #000;
}
.align-content-space-around {
  align-content: space-around;
}
.align-content-flex-start {
  align-content: flex-start;
}
.align-items-center {
  align-items: center;
}
.align-items-flex-start {
  align-items: flex-start;
}
.align-items-flex-end {
  align-items: flex-end;
}
.align-items-left {
  align-items: flex-start;
}
.align-items-right {
  align-items: flex-end;
}
.align-self-center {
  align-self: center;
}
.align-items-stretch {
  align-items: stretch;
}
.align-self-flex-end {
  align-self: flex-end;
}
.align-self-flex-start {
  align-self: flex-start;
}
.bg-aliceblue {
  background: #f3f7fe;
}
.bg-black {
  background: #000;
}
.bg-charcoal {
  background: #1f2027;
}
.bg-darkcyan {
  background: #029b7c;
}
.bg-darkgray {
  background: #333;
}
.bg-darkorange {
  background: #ff8a00;
}
.bg-darkturquoise {
  background: #01c0d8;
}
.bg-deepskyblue {
  background: #019fe8;
}
.bg-gray {
  background: #909090;
}
.bg-green {
  background: #2fb457;
}
.bg-khaki {
  background: #f8d775;
}
.bg-lemonchiffon {
  background: #fff3cd;
}
.bg-lightgray {
  background: #ccc;
}
.bg-olive {
  background: #7fba00;
}
.bg-orange {
  background: #ff9f00;
}
.bg-orangered {
  background: #f25022;
}
.bg-red {
  background: #f00;
}
.bg-sandybrown {
  background: #f9ce5e;
}
.bg-snow {
  background: #fafafa;
}
.bg-tomato {
  background: #e74c4b;
}
.bg-whitesmoke {
  /* background: #f4f4f4; */
  background: #f5f5f7;
}
.opacity-75 {
  opacity: 75%;
}
.bg-snow-pattern {
  background-image: linear-gradient(45deg, #f4f4f4 25%, transparent 25%, transparent 50%, #f4f4f4 50%, #f4f4f4 75%, transparent 75%, transparent);
  background-size: 5px 5px;
}
.bg-whitesmoke-pattern {
  /* background: #f4f4f4; */
  background-image: linear-gradient(45deg, #eaeaea 25%, transparent 25%, transparent 50%, #eaeaea 50%, #eaeaea 75%, transparent 75%, transparent);
  background-size: 5px 5px;
}
.bg-white {
  background-color: #fff;
}
.border {
  border: solid 1px #ccc;
}
.border-bottom-gray {
  border-bottom: solid 1px #ccc;
}
.border-black {
  border: solid 1px #000;
}
.border-gray {
  color: #808080;
}
.border-left-gray {
  border-left: solid 1px #ccc;
}
.border-light-gray {
  border: solid 1px #ccc;
}
.border-darkgray {
  border: solid 1px #999;
}
.border-darkorange {
  border: solid 1px #ff9f00;
}
.border-dodgerblue {
  border: solid 1px #018fd0;
}
.border-royalblue {
  border: solid 1px #3367d6;
}
.border-whitesmoke {
  border: solid 1px #f8f8f8;
}
.border-none {
  border: none;
}
.border-radius-2 {
  border-radius: 2px;
}
.border-radius-5 {
  border-radius: 5px;
}
.border-radius-10 {
  border-radius: 10px;
}
.border-radius-25 {
  border-radius: 25px;
}
.border-radius-bottom-5px {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.border-radius-top-5px {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.border-radius-bottom-25px {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}
.border-radius-top-25px {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.border-radius-5 {
  border-radius: 5px;
}
.border-radius-25 {
  border-radius: 25px;
}
.border-radius-35 {
  border-radius: 35px;
}
.border-red {
  border: solid 1px red;
}
.border-bottom-gray {
  border-bottom: solid 1px #ccc;
}
.border-style-dashed {
  border-style: dashed;
}
.border-style-dotted {
  border-style: dotted;
}
.border-top-gray {
  border-top: solid 1px #ccc;
}
.border-top-whitesmoke {
  border-top: solid 1px #f8f8f8;
}
.border-width-5 {
  border-width: 5px;
}
.box-shadow-light {
  /* -webkit-box-shadow: 0 7px 15px 0px #888; */
  /* -moz-box-shadow: 0 7px 15px 0px #888; */
  box-shadow: 0 2px 10px 0px #ddd;
}
.box-sizing-border-box {
  box-sizing: border-box;
}
.business-avatar {
  width: 175px;
  height: 175px;
  object-fit: cover;
}
.businessSearchBar {
  line-height: 30px;
  width: 100%;
  padding-left: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border: solid 1px #ccc;
  background-clip: padding-box;
  outline: none;
  box-shadow: none !important;
}
.button-faux-link {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  color: #2d8cff;
  text-decoration: none;
  cursor: pointer;
}
.button-faux-link:hover {
  text-decoration: underline;
  /* color: #000; */
  /* background: #eee; */
}
.card-content {
  max-width: 300px;
  cursor: pointer;
  /* border: solid 1px red; */
  transition: all 0.15s ease-in-out;
}
.card-content .card-content-withfullimg {
  flex-direction: row;
  justify-content: center;
}
.card-img-full {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  display: block;
}
.card-content:hover {
  /* border: solid 1px #007bff; */
  box-shadow: 0 3px 10px 0px #333;
  transform: scale(1.025);
}
.card-content a {
  color: #000;
}
.card-content a:hover {
  text-decoration: none;
}
.color-black {
  color: #000;
}
.color-black:hover {
  color: #000;
}
.color-blue {
  color: #3ba0f3;
}
.color-charcoal {
  color: #1f2027;
}
.color-darkcyan {
  color: #029b7c;
}
.color-darkorange {
  color: #ff8a00;
}
.color-deepskyblue {
  color: #019fe8;
}
.color-darkslateblue {
  color: #642d92;
}
.color-dimgray {
  color: #606060;
}
.color-firebrick {
  color: #b12704;
}
.color-gold {
  color: #fdc014;
}
.color-lightgray {
  color: #ccc;
}
.color-gray {
  color: #808080;
}
.color-gray:hover {
  color: #808080;
}
.color-green {
  color: #00b506;
}
.color-lightgray {
  color: #ccc;
}
.color-lightgray:hover {
  color: #ccc;
}
.color-mediumseagreen {
  color: #22b66e;
}
.color-olive {
  color: #7fba00;
}
.color-paleturquoise {
  color: #bcefff;
}
.color-red {
  color: #f00;
}
.color-royalblue {
  color: #3b78e7;
}
.color-whitesmoke {
  color: #f4f4f4;
}
.color-tomato {
  color: #e74c4b;
}
.color-white {
  color: #fff;
}
.container-box-small {
  /* background-color:#edeff7; */
  margin: 55px auto;
  max-width: 560px;
  /* border: 1px solid #d0d0d0; */
  padding: 20px;
  /* border-radius: 2px;
  -moz-outline-radius: 2px; */
  /* background-color: #fff; */
}
.container-box-medium {
  /* background-color:#edeff7; */
  margin: 55px auto;
  max-width: 768px;
  /* border: 1px solid #d0d0d0; */
  padding: 20px;
  border-radius: 2px;
  -moz-outline-radius: 2px;
  /* background-color: #fff; */
}
.cursor-move {
  cursor: move; /* fallback: no `url()` support or images disabled */
  cursor: -webkit-grab; /* Chrome 1-21, Safari 4+ */
  cursor: -moz-grab; /* Firefox 1.5-26 */
  cursor: grab; /* W3C standards syntax, should come least */
}
.cursor-move:active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
.display-block {
  display: block;
}
.display-flex {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.display-inline-flex {
  display: inline-flex;
}
.display-inline {
  display: inline;
}
.display-none {
  display: none;
}
.display-inline-block {
  display: inline-block;
}
/* desktop */
.embedded-video-small {
  width: 82vw;
  height: 50.625vw; /* 90*9/16 */
  margin-left: 5vw;
  margin-right: 5vw;
}
/* mobile */
@media (min-width: 893px) {
  .embedded-video-small {
    width: 45vw;
    height: 25.3125vw; /* 45*9/16 */
    margin-left: 2vw;
    margin-right: 2vw;
  }
}
.flex-1 {
  flex: 1;
  clear: both;
}
.flex-1-1-auto {
  flex: 1 1 auto;
}
.flex-2 {
  flex: 2;
  clear: both;
}
.flex-3 {
  flex: 3;
  clear: both;
}
.flex-basis-auto {
  flex-basis: auto;
}
.flex-direction-row {
  flex-direction: row;
}
.flex-direction-row-reverse {
  flex-direction: row-reverse;
}
.flex-direction-column {
  flex-direction: column;
}
.flex-grid-1x4 {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  margin: 10px 0;
}
.flex-grid-1x4 > div {
  flex-grow: 1;
  margin: 5px;
  padding: 10px;
  flex-basis: calc(25% - 20px); /* width minus margins */
}
.flex-grid-2x2 {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  margin: 10px 0;
}
.flex-grid-2x2 > div {
  flex-grow: 1;
  margin: 0;
  padding: 10px;
  flex-basis: calc(50% - 20px); /* width minus margins */
}
.flex-grow-1 {
  flex-grow: 1;
}
.flex-grow-2 {
  flex-grow: 2;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-shrink-1 {
  flex-shrink: 1;
}
.flex-shrink-2 {
  flex-shrink: 1;
}
.flex-table {
  /* display: flex;
  flex-direction: column; */
  display: table;
  border-collapse: collapse;
}
.flex-table-row {
  /* display: flex; */
  display: table-row;
}
.flex-table-cell {
  display: table-cell;
  /* width: fit-content; */
  /* border: solid 1px red; */
}
.flex-table-cell a {
  color: #666;
}
.flex-table-cell a:hover {
  text-decoration: none;
  color: #666;
}
.flex-table-cell .footer-links div {
  /* border: solid 1px blue; */
  margin-bottom: 10px;
}
.flex-table-row-even-spacing {
  display: flex;
}
.flex-table-row-even-spacing .flex-table-cell:nth-child(1) {
  flex: 1;
}
.flex-table-row-even-spacing .flex-table-cell:nth-child(2) {
  flex: 2;
}
.flex-wrap-nowrap {
  flex-wrap: nowrap;
}
.flex-wrap-wrap {
  flex-wrap: wrap;
}
.font-weight-bold {
  font-weight: bold;
}
.font-weight-bolder {
  font-weight: bolder;
}
.icon-small {
  width: 15px;
}
.icon-medium {
  width: 100px;
  padding: 10px;
  margin: 10px;
  font-size: 40px;
}
img {
  max-width: 100%;
  height: auto;
  width: auto\9; /* ie8 */
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.font-family-proximanova {
  font-family: 'ProximaNova';
}
.font-size-check-in {
  font-size: 18vmin;
}
.font-size-smallest {
  font-size: 0.75em;
}
.font-size-smaller {
  font-size: 0.85em;
}
.font-size-small {
  font-size: 0.95em;
}
.font-size-normal {
  font-size: 1.05em;
}
.font-size-medium {
  font-size: 1.15em;
}
.font-size-medium-large {
  font-size: 1.5em;
}
.font-size-large {
  font-size: 1.75em;
}
.font-size-larger {
  font-size: 2em;
}
.font-size-largest {
  font-size: 3em;
}
.font-size-xlarge {
  font-size: 2em;
}
.font-style-italic {
  font-style: italic;
}
.footer-sticky-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.footer-sticky-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.group-container {
  padding: 80px 8px;
  overflow: hidden;
}
.group-content {
  justify-content: center;
  font-size: 20px;
}
.height-100 {
  min-height: 100%;
}
.height-140px {
  height: 140px;
}
.height-100vh-minus-header {
  height: calc(100vh - 80px); /* subtract vh to accommodate for the header */
}
.height-100vh {
  height: 100vh;
}
.hr-text {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  /* color: rgba(0, 0, 0, 0.35); */
  margin: 8px 0px;
}
.hr-text::before,
.hr-text::after {
  content: '';
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.35);
  height: 1px;
  font-size: 0px;
  line-height: 0px;
}
input.border-none {
  border: none;
}
input.font-size-large {
  font-size: 2.75em;
}
input.checkbox {
  width: 25px;
  height: 25px;
}
input.largerCheckbox {
  width: 25px;
  height: 25px;
}
input.checkin-checkbox {
  border-radius: 0;
  width: 25px;
  height: 25px;
}
.input-text-checkinapp {
  padding: '25px 10px';
  font-size: '20px';
  width: '90%';
}
.radio-container input[type='radio'] {
  width: 25px;
  height: 25px;
}
.justify-content-space-between {
  justify-content: space-between;
}
.justify-content-space-around {
  justify-content: space-around;
}
.justify-content-space-evenly {
  justify-content: space-evenly;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-flex-end {
  justify-content: flex-end;
}
.justify-content-flex-start {
  justify-content: flex-start;
}
.justify-content-right {
  justify-content: right;
}
.left-65 {
  left: 65px;
}
.list-style-none {
  list-style: none;
}
.processing {
  animation: processing-spin infinite 1s linear;
}
@keyframes processing-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.logo-static {
  height: 70px;
}
.logo-static-thumb {
  height: 30px;
}
.logo {
  animation: logo-spin infinite 20s linear;
  height: 100px;
}
@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.margin-3 {
  margin: 3px;
}
.margin-5 {
  margin: 5px;
}
.margin-10 {
  margin: 10px;
}
.margin-15 {
  margin: 15px;
}
.margin-25 {
  margin: 25px;
}
.margin-50 {
  margin: 50px;
}
.margin-auto {
  margin: 0 auto;
}
.margin-bottom-5 {
  margin-bottom: 5px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-bottom-15 {
  margin-bottom: 15px;
}
.margin-bottom-25 {
  margin-bottom: 25px;
}
.margin-bottom-50 {
  margin-bottom: 50px;
}
.margin-top-3 {
  margin-top: 3px;
}
.margin-top-5 {
  margin-top: 5px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-15 {
  margin-top: 15px;
}
.margin-top-25 {
  margin-top: 25px;
}
.margin-top-50 {
  margin-top: 50px;
}
.margin-top-bottom-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.margin-left-5 {
  margin-left: 5px;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-left-15 {
  margin-left: 15px;
}
.margin-left-right-15 {
  margin-left: 15px;
  margin-right: 15px;
}
.margin-right-5 {
  margin-right: 5px;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-right-15 {
  margin-right: 15px;
}
.margin-left-auto {
  margin-left: auto;
}
.margin-right-auto {
  margin-right: auto;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.checkin-waitlist-monitor {
  font-size: 4vw;
}
.checkin-waitlist-monitor table thead {
  background: #fff;
}
.checkin-waitlist-monitor table tbody tr:nth-child(1) {
  background: #0f2140;
  color: #fff;
}
.checkin-waitlist-monitor table tr td {
  padding: 20px;
  width: 33%;
}
.checkin-waitlist-monitor-table tr td:nth-child(2) {
  text-align: center;
}
.checkin-waitlist-monitor-table tr td:last-child {
  text-align: right;
}
.checkinapp-agreement-parent {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
.checkinapp-agreement-child {
  height: 60vh;
}
.outline-0 {
  outline: 0;
}
.overflow-x-auto {
  overflow-x: auto;
}
.padding {
  padding: 15px;
}
.padding-3 {
  padding: 3px;
}
.padding-5 {
  padding: 5px;
}
.padding-10 {
  padding: 10px;
}
.padding-25 {
  padding: 25px;
}
.padding-50 {
  padding: 50px;
}
.padding-bottom-none {
  padding-bottom: none;
}
.padding-left-0 {
  padding-left: 0px;
}
.padding-left-10 {
  padding-left: 10px;
}
.padding-right-0 {
  padding-right: 0px;
}
.padding-right-10 {
  padding-right: 10px;
}
.padding-left-15 {
  padding-left: 15px;
}
.padding-right-15 {
  padding-right: 15px;
}
.padding-left-right-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.padding-top-none {
  padding-top: none;
}
.padding-top-5 {
  padding-top: 5px;
}
.padding-top-10 {
  padding-top: 10px;
}
.padding-top-15 {
  padding-top: 15px;
}
.padding-bottom-5 {
  padding-bottom: 5px;
}
.padding-bottom-10 {
  padding-bottom: 10px;
}
.padding-bottom-15 {
  padding-bottom: 15px;
}
.padding-top-bottom-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.preview {
  height: 200px;
  width: 200px;
  object-fit: cover;
}
.preview-container {
  display: inline-block;
  border: solid 7px black;
  overflow: hidden;
  background-color: #000;
}
.preview-landscape {
  width: 480px;
  height: 270px;
}
.preview-portrait {
  height: 480px;
  width: 270px;
}
.preview-item {
  max-width: 100%;
  max-height: 100%;
}
.preview-media {
  /* max-width: 420px; */
  /* max-height: 640px; */
  width: 100% !important;
  height: auto !important;
}
.position-absolute {
  position: absolute;
}
.position-absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.position-relative {
  position: relative;
}
.position-sticky {
  position: sticky;
}
.ql-editor {
  min-height: 200px;
}
.QRCode-container {
  display: inline-block;
  padding: 0.6vw 1.2vw;
}
.QRCode-text {
  font-size: 4.3vw;
}
.react-quill p {
  margin-bottom: 0px; /* fixes the p tag issue */
}
.resize-both {
  resize: both;
}
.row-selection-option {
  position: 'absolute';
  left: '0';
  top: '0';
  background: '#f4f4f4';
  padding: '2px';
  border: 'solid 1px red';
  width: '200px';
}
.services-overview-ul {
  margin: 15px;
}
.services-overview-ul li {
  padding: 2px;
}
.scroll-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  width: 100%;
  height: 60vh;
}
.scroll-wrapper iframe {
  height: 100%;
  width: 100%;
}
.shape-triangle-container {
  height: 25px;
  width: 100%;
  background: #fff;
  text-align: center;
}
.shape-triangle-middle-top {
  height: 0px;
  width: 0px;
  border-width: 0px 50px 25px 50px;
  display: inline-block;
  border-style: solid;
  border-color: transparent transparent #000 transparent;
}
.show-hide-on-hover:hover .hide-on-hover {
  opacity: 0;
}
.show-on-hover {
  display: none;
}
.show-hide-on-hover:hover .show-on-hover {
  display: block;
}
.strikethrough {
  position: relative;
}
.strikethrough:before {
  position: absolute;
  content: '';
  left: 0;
  top: 50%;
  right: 0;
  border-top: 1px solid;
  border-color: inherit;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}
.table-business-hours td {
  padding: 8px;
}
.table-passmobileprint-instructions {
  font-size: 0.5em;
}
.table-passmobileprint-instructions td {
  padding: 5px;
  text-align: center;
  vertical-align: top;
  width: '33.33%';
}
.table-subscription-overview {
  padding: 15px;
  width: 100%;
}
.table-subscription-overview tr td {
  padding: 5px;
}
.table-subscription-overview tr td:nth-child(1) {
  text-align: left;
  width: 20px;
}
.table-subscription-plan {
  width: 100%;
}
.table-subscription-plan td {
  padding: 10px 15px;
  border: solid 1px #eaeaea;
}
.table-subscription-plan tr:first-child td {
  border: none;
}
.table-subscription-plan tr:nth-child(2) td {
  border: none;
  background-color: #fff;
}
.table-subscription-plan tr:last-child td {
  border: none;
}
.table-subscription-plan tr td:nth-child(1) {
  text-align: left;
  width: 10px;
}
.table-subscription-plan tr td:nth-child(2) {
  text-align: left;
  width: 200px;
}
.table-subscription-plan tr td:nth-child(3) {
  text-align: center;
}
.table-subscription-plan tr td:nth-child(4) {
  text-align: center;
}
.table-subscription-plan tr td:nth-child(5) {
  text-align: center;
}
.table-subscription-plan tr:nth-child(even) {
  background-color: #f6f6f6;
}
.table-waitlist {
  width: 100%;
}
.table-waitlist thead tr:first-child {
  background: #fff;
}
.table-waitlist thead tr:first-child td {
  padding: 25px;
}
.table-waitlist tr td {
  padding: 25px;
}
.table-waitlist tr:nth-child(odd) {
  background: #ededed;
}
.table-waitlist tr:nth-child(even) {
  background: #fff;
}
.table-padding td {
  padding: 5px;
}
.text-align-center {
  text-align: center;
}
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.text-decoration-none {
  text-decoration: none;
}
.text-decoration-underline {
  text-decoration: underline;
}
.text-decoration-line-through {
  text-decoration: line-through;
}
.text-decoration-none:hover {
  text-decoration: none;
}
.text-transform-capitalize {
  text-transform: capitalize;
}
.text-transform-initial {
  text-transform: initial;
}
.text-transform-lowercase {
  text-transform: lowercase;
}
.text-transform-uppercase {
  text-transform: uppercase;
}
.thumbnail {
  width: 60px;
  height: 60px;
  object-fit: cover;
}
.thumbnail-40 {
  height: 40px;
  width: 40px;
  object-fit: cover;
}
.transform-rotate-180 {
  transform: rotate(180deg);
}
.ul-list-appointment-services {
  list-style: none;
  padding-left: 0;
}
.ul-list-appointment-services li {
  margin-bottom: 5px;
}
.ul-list-subscription-features {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-top: 15px;
  padding-left: 0;
  font-size: 0.85em;
  justify-content: center;
  /* border: solid 1px red; */
}
.ul-list-subscription-features li {
  display: flex;
  padding: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  align-items: center;
  /* border: solid 1px red; */
}
.ul-list-dashboard-features {
  display: flex;
  /* flex-direction: column; */
  list-style: none;
  margin: 15px;
  padding-left: 0;
  flex-wrap: wrap;
}
.ul-list-dashboard-features li {
  display: flex;
  /* border: solid 1px red; */
}

.uppy-Dashboard-inner {
  max-height: 320px;
}
.vertical-align-bottom {
  vertical-align: bottom;
}
.vertical-align-middle {
  vertical-align: middle;
}
.vertical-align-top {
  vertical-align: top;
}
video {
  height: 100%;
}
.video-container {
  width: 100%;
  background-size: cover;
  position: relative;
  background-color: #161b1f;
  display: flex;
  align-items: center;
  justify-content: center;
}
#video-landing {
  /* position: relative; */
  /* height: 555px; */
  /* this is the height of the landing page video , keep this the video message in place during refresh or loading of the page */
  background-color: #161b1f;
  /* background-size: cover; */
  width: 100%;
}
.video-message-container {
  text-shadow: 3px 3px 3px #000;
  color: white;
  width: 100%;
  position: absolute;
}
.video-message {
  font-size: 75px; /* fallback support */
  font-size: 3.8vw;
  font-family: 'ProximaNova';
}
.video_message_sub {
  font-size: 35px;
  font-size: 1.9vw;
}
.white-space-nowrap {
  white-space: nowrap;
}
.width-1 {
  width: 1%;
}
.width-100 {
  width: 100%;
}
.width-100vw {
  width: 100vw;
}
.width-50 {
  width: 50%;
}
.width-auto {
  width: auto;
}
.width-inherit {
  width: inherit;
}
.width-max-360 {
  max-width: 360px;
}
.width-max-640 {
  max-width: 640px;
}
.width-max-760 {
  max-width: 760px;
}
.width-980 {
  width: 980px;
}
.width-max-980 {
  max-width: 980px;
}
.wrapper-link:hover .display-none {
  display: inline-flex;
}
/* Instagram Grid */
.square-container {
  display: flex;
  flex-wrap: wrap;
  /* border: solid 1px red; */
  align-items: center;
  /* justify-content: center; */
}
.square {
  position: relative;
  flex-basis: calc(33.333% - 4px); /* must be double the margin below */
  margin: 2px; /*space between each posts */
  box-sizing: border-box;
}
.square::before {
  content: '';
  display: block;
  padding-top: 100%;
}
.square .content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.square-small {
  position: relative;
  flex-basis: calc(15% - 4px); /* must be double the margin below */
  margin: 2px; /*space between each posts */
  box-sizing: border-box;
}
.square-small::before {
  content: '';
  display: block;
  padding-top: 100%;
}
.square-small .content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
/* Post Media */
.post-media {
  width: 100%;
}
