/* COLORS STYLES
   ----------------------------- */
.bg-orange {
  background-color: $colorOrange !important;
}
.bg-blue {
  background-color: $colorBlue !important;
}
.bg-gold {
  background-color: $colorGold !important;
}
.bg-white {
  background-color: #ffffff !important;
}
.bg-black {
  background-color: $colorBlack !important;
}
.bg-light-grey {
  background-color: $colorLightGrey !important;
}
.bg-primary {
  background-color: $colorPrimary !important;
}
.bg-success {
  background-color: $colorSuccess !important;
}
.bg-warning {
  background-color: $colorWarning !important;
}
.bg-danger {
  background-color: $colorDanger !important;
}
.bg-info {
  background-color: $colorInfo !important;
}
.bg-facebook {
  background-color: $colorFacebook;
}
.bg-twitter {
  background-color: $colorTwitter;
}
.bg-linkedin {
  background-color: $colorTwitter;
}
.bg-github {
  background-color: $colorTwitter;
}

.text-facebook {
  color: $colorFacebook;
}
.text-twitter {
  color: $colorTwitter;
}
.text-linkedin {
  color: $colorLinkedin;
}
.text-github {
  color: $colorGithub;
}

.text-primary {
  color: $colorPrimary !important;
}

.text-success {
  color: $colorSuccess !important;
}

.text-info {
  color: $colorInfo !important;
}

.text-warning {
  color: $colorWarning !important;
}

.text-danger {
  color: $colorDanger !important;
}

.text-gold {
  color: $colorGold !important;
}

.text-muted {
  color: $colorTextMuted;
}
.text-facebook {
  color: $colorFacebook;
}
.text-twitter {
  color: $colorTwitter;
}
