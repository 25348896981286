/* Portal Position */
.react-datepicker__portal {
  align-items: baseline;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-weight: normal;
  font-size: 1em;
}
.react-datepicker__header {
  background-color: #fff;
}
.dashboard-datepicker .react-datepicker__header {
  padding: 10px;
  font-size: 1.5em;
}
.dashboard-datepicker .react-datepicker__month .react-datepicker__month-text {
  padding: 10px;
  border-radius: 5px;
}
/* Month Navigation Arrows */
.react-datepicker__portal .react-datepicker__navigation--next,
.react-datepicker__portal .react-datepicker__navigation--next:hover,
.react-datepicker__portal .react-datepicker__navigation--next:active {
  border-left-color: #019fe8;
  outline: none;
}
.react-datepicker__portal .react-datepicker__navigation--previous,
.react-datepicker__portal .react-datepicker__navigation--previous:hover,
.react-datepicker__portal .react-datepicker__navigation--previous:active {
  border-right-color: #019fe8;
  outline: none;
}
.react-datepicker__day--keyboard-selected {
  background: none;
  color: #000;
}
.react-datepicker__month {
  margin: 0;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today {
  color: #019fe8;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 2.5rem;
  line-height: 2.5rem;
}
/* Day and Time Selection Background */
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  background-color: #019fe8;
  color: #fff;
  border-radius: 1.5rem;
  outline-width: 0;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  border-radius: 1.5rem;
  outline-width: 0;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  display: flex;
  align-items: center;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #019fe8;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  align-items: center;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  display: none;
}
