.app-sidebar {
  position: fixed;
  z-index: 100; //999; // this keeps the navigation above everything when toggling open and close in mobile view
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: $sidebarWidth;
  background: $sidenavBgColor;
  display: flex;
  flex-direction: column;
  border-right: solid 1px #ccc;
  &.has-alert {
    top: 48px;
  }

  > nav {
    flex: 1 1;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0px;
    }
  }
}

.site-logo-bar {
  overflow: hidden;
  background: $sidenavBgColor;
  .navbar-brand {
    width: 100%;
    padding: 10px 20px;
    color: white;
    margin-right: 0;

    .keyboardActive &:focus {
      outline: none;
      box-shadow: inset 0px 0px 0px 2px $sideNavBgActive !important;
    }

    > img {
      max-height: 30px;
      max-width: 30px;
      display: inline-block;
    }

    .logo-text {
      // margin-left: 8px;
      opacity: 1;
      vertical-align: middle;

      img {
        max-height: 40px;
        max-width: 50px;
      }
    }
  }
}

// Collaped Side Menu
@media screen and (min-width: $breakPointSm) {
  .side-menu-collapsed {
    .site-logo-bar {
      .navbar-brand {
        padding: 10px;
        .logo-text {
          opacity: 0;
        }
      }
    }
  }
}

#main-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  .nav-item {
    position: relative;
    &.active {
      > a {
        border-color: $activeNavBorderColor;
      }
    }
    &.has-submenu {
      ul {
        list-style: none;
        padding: 0;
        height: 0;
        overflow: hidden;
        // background: darken($sidenavBgHover, 0.5%); // background color for submenu
        .nav-item {
          .keyboardActive &:focus,
          &:hover {
            a {
              background: darken($sidenavBgHover, 6%);
            }
          }

          a {
            padding-left: 35px;
            &.active {
              background: $sideNavBgActive; //darken($sidenavBgHover, 6%);
            }
            .keyboardActive &:focus {
              outline: none;
              box-shadow: inset 0px 0px 0px 2px $sideNavBgActive;
            }
          }
        }
      }

      &.open {
        ul {
          height: auto;
        }
      }
    }

    &.submenu.open {
      .menu-expand-icon {
        transform: rotate(90deg);
      }
    }

    > a {
      display: flex;
      width: 100%;
      text-decoration: none;
      padding: 10px 20px;
      // color: $sideNavTextColor;
      color: #333;
      border-color: transparent;
      align-items: center;
      // &.active {
      //   // color: #fff;btnColorSuccess
      // }
      &:hover {
        background: $sidenavBgHover;
      }
      &:focus {
        outline: none;
      }
      .keyboardActive &:focus {
        box-shadow: inset 0px 0px 0px 2px $sideNavBgActive !important;
      }
      .side-nav-icon {
        width: 20px;
      }
      &.active {
        background-color: darken($color: #bcefff, $amount: 5);
        color: #000;
        // box-shadow: inset 5px 0px #018fd0;
        // border-top: solid 1px #018fd0;
        // border-bottom: solid 1px #018fd0;
      }
      &.active .side-nav-icon {
        color: #019fe8;
      }
    }
    .nav-item-label {
      opacity: 1;
      // border: solid 1px red;
      // padding: 5px;
    }
    .badge {
      opacity: 1;
      vertical-align: middle;
      margin-left: auto;
    }

    &.nav-item-spacer {
      flex: 1;
    }
    .side-nav-icon {
      font-size: 25px;
      margin-right: 10px;
      vertical-align: middle;
      position: relative;
      top: 2px;
      left: 0px;
    }
  }
  li.separator {
    border-top: 1px solid $sideNavSeparatorColor;
    // margin: 10px 20px;
  }
  .menu-expand-icon {
    margin-right: 0;
    line-height: 20px;
    // font-size: 11px;
    position: absolute;
    right: 20px;
    top: 16px;
    transform: rotate(0deg);
  }
  .badge {
    background: $sideNavBgActive;
  }
}

// Collapsed Styles
@media screen and (min-width: $breakPointSm) {
  .app:not(.side-menu-collapsed) {
    .app-sidebar {
      > nav {
        flex: 1 1;
        overflow-y: auto;
        height: 100%;
      }
    }
  }
  .side-menu-collapsed {
    .app-sidebar {
      width: 50px;

      .site-logo {
        padding: 5px;

        img {
          width: 100%;
        }
      }
    }

    ul#main-menu {
      li.nav-item {
        position: relative;
        width: 50px;

        a {
          padding: 10px 12px;
          text-align: center;

          i {
            padding-right: 0px;
            left: 3px;
          }
          .side-nav-icon {
            margin-right: 0px;
            margin-left: 3px;
          }
          .nav-item-label,
          .badge,
          .menu-expand-icon {
            display: none;
          }
        }

        &.has-submenu {
          > .nav-submenu {
            margin-left: -20px;
            height: auto;
            display: none;
            z-index: 999;

            li.nav-item {
              width: 240px;

              .nav-item-label {
                opacity: 1;
                display: block;
              }

              .side-nav-icon {
                // display: none;
                margin-right: 10px;
              }

              a {
                padding-left: 20px;
                border-color: transparent;
                text-align: left;
              }
            }
          }

          .keyboardActive &:focus,
          &:hover {
            .nav-submenu {
              position: absolute;
              top: 0;
              left: 50px;
              margin-left: 0;
              display: inline;
              max-height: 1000px;
              border-top: solid 1px #ccc;
              border-bottom: solid 1px #ccc;
              border-right: solid 1px #ccc;
              // background: #000;
            }
          }
        }
      }
      li.separator {
        margin: 10px 15px;
      }
    }
  }
}

@media screen and (max-width: $breakPointSm) {
  .app-sidebar {
    transform: translate(-100%);
    transition: all 0.3s ease;
  }
  .sidebar-overlay {
    background: $sidebarMobileOverlay;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
  }
  .side-menu-collapsed {
    .app-sidebar {
      transform: translate(0%);
      > nav {
        overflow-y: auto;
      }
    }
    // Stop app from scrolling when scrolling through app-sidebar
    overflow: hidden;
    height: 100vh;
  }
}
