/* AVATARS STYLES
   ----------------------------- */

.avatar {
  border-radius: 70px;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 0;

  .user-initials {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    line-height: 30px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }

  img {
    line-height: 0;
    position: relative;
    border-radius: 2px;
    display: inline-block;
  }

  &.avatar-sm {
    width: 24px;
    height: 24px;

    .user-initials {
      line-height: 24px;
      font-size: 24px;
    }
  }

  &.avatar-md {
    width: 48px;
    height: 48px;

    .user-initials {
      line-height: 48px;
      font-size: 24px;
    }
  }

  &.avatar-lg {
    width: 100px;
    height: 100px;

    .user-initials {
      line-height: 100px;
      font-size: 64px;
    }
  }
}
